import React from "react";
import EducationPopUp from "../components/EducationPopUp";
export default class Education extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      educationType: "",
    };
  }

  openModal = (experience) => {
    this.setState({ openModal: true });
    this.setState({ educationType: experience });
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  highSchoolButtonClick() {
    // Define the URL you want to open in a new tab
    const url = "https://www.example.com";

    // Open the URL in a new tab
    window.open(url, "_blank");
  }

  render() {
    return (
      <div
        className="flex flex-row justify-center bg-gray-50 h-fit pt-[85px] min-h-[700px] place-items-center"
        id="Education"
      >
        <main className="w-[65%] h-full flex flex-col space-y-6 ">
          <div className="flex flex-row justify-center w-full h-fit place-items-center">
            <h1 className="text-4xl font-primary">Education</h1>
          </div>
          <div className="flex flex-row justify-center w-full h-fit place-items-center">
            <div className="justify-center space-y-4 font-thin text-center text-gray-500 text-md font-primary">
              <p>My academic journey.</p>
            </div>
          </div>
          <div className="flex flex-row justify-center w-full h-fit place-items-center">
            <div className="justify-center space-y-4 font-thin text-center text-gray-500 text-md font-primary">
              <p> {/* My personal journey */}</p>
            </div>

            {/* <div class="grid grid-cols-2 gap-8 w-full pt-4">
             <ExperienceCard/>
             <ExperienceCard/>
             <ExperienceCard/>
             <ExperienceCard/>

            </div> */}

            <EducationPopUp
              open={this.state.openModal}
              handleClose={this.closeModal}
              experience={this.state.educationType}
            />

            <div class="">
              <div class=" pb-20 relative pl-8 sm:pl-32 py-6 group">
                <div class="font-primary font-medium text-2xl text-black mb-1 sm:mb-0">
                  Concordia University
                </div>

                <div class="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-gray-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-button-primary after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
                  <time class="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-white bg-button-primary rounded-full">
                    Jan, 2023
                  </time>
                  <div class="font-primary text-md text-black font-thin ">
                    Independent Graduate Student
                  </div>
                </div>

                <div class=" font-primary text-sm text-gray-500 mb-4">
                  Part time independent student taking graduate courses in the
                  mathematics and computer science departments.
                </div>

                <button
                  className="flex flex-row p-2 px-3 text-sm text-white bg-button-primary rounded-3xl place-items-center hover:bg-button-hover"
                  onClick={() => this.openModal("concordia")}
                >
                  {" "}
                  View Details →{" "}
                </button>
              </div>

              <div class=" relative pl-8 sm:pl-32 py-12 group ">
                <div class="font-primary font-medium text-2xl text-black mb-1 sm:mb-0 pb-2">
                  McGill University
                </div>

                <div class="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-gray-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-button-primary after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
                  <time class="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-white bg-button-primary rounded-full">
                    May, 2020
                  </time>
                  <div class="font-primary text-md text-black font-thin ">
                    Bachelor of Science (B.Sc.) <br></br> Major: Computer
                    Science • Minor: Mathematics
                  </div>
                </div>

                <div class=" font-primary text-sm text-gray-500 mb-4">
                  Undergraduate degree in computer science and mathematics with
                  a focus in graduate level statistics.
                </div>

                <button
                  className="flex flex-row p-2 px-3 text-sm text-white bg-button-primary rounded-3xl place-items-center hover:bg-button-hover"
                  onClick={() => this.openModal("mcgill")}
                >
                  {" "}
                  View Details →{" "}
                </button>
              </div>

              <div class="relative pl-8 sm:pl-32 py-20 group">
                <div class="font-primary font-medium text-2xl text-black mb-1 sm:mb-0 pb-2">
                  Westview High School
                </div>

                <div class="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-gray-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-button-primary after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
                  <time class="sm:absolute left-0 w-20 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase 4 h-6 mb-3 sm:mb-0 text-white bg-button-primary rounded-full">
                    June, 2015
                  </time>
                  <div class="font-primary text-md text-black font-thin ">
                    High School Diploma
                  </div>
                </div>

                <div class=" font-primary text-sm text-gray-500 mb-4">
                  Attended high school in San Diego, California.
                </div>

                {/* <button 
                  className="flex flex-row p-2 px-3 text-sm text-white bg-button-primary rounded-3xl place-items-center hover:bg-button-hover"
                  onClick={this.highSchoolButtonClick}
                >
                  {" "}
                  View Details →{" "}
                </button> */}
              </div>

              {/* <div class="relative pl-8 sm:pl-32 py-6 group">
                <div class="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-gray-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-button-primary after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
                  <time class="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-white bg-button-primary rounded-full">
                    June, 2015
                  </time>
                  <div class="flex flex-col w-[70%] h-[250px] px-3 py-2 rounded-xl shadow-sm border-[1px] bg-white">

                    <div class="font-primary font-medium text-2xl text-black mb-1 sm:mb-0 pb-2">
                        Westview High School
                    </div>
                    <div className="mb-1 font-thin text-black font-primary text-md ">
                        High School Diploma
                    </div>
                    <div class=" font-primary text-sm text-gray-500">
                    Pretium lectus quam id leo. Urna et pharetra pharetra massa
                    massa. Adipiscing enim eu neque aliquam vestibulum morbi
                    blandit cursus risus.
                    </div>
                    
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </main>
      </div>
    );
  }
}
