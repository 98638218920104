import React, { useEffect, useState } from "react";
import "../css/AnimatedText.css";

const AnimatedText = (props) => {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowText(true);
    }, props.delay || 0);

    return () => clearTimeout(timeoutId); // Clear the timeout if the component unmounts before the delay is over
  }, [props.delay]);

  return (
    <div className={`animated-text ${showText ? "show" : ""}`}>
      {props.text}
    </div>
  );
};

export default AnimatedText;
