import { Link } from "react-scroll";
import "../index.css";

const Navbar = () => {
  const menuItems = [
    {
      id: 1,
      title: "Skills",
    },
    {
      id: 2,
      title: "Experiences",
    },
    {
      id: 3,
      title: "Education",
    },
    {
      id: 5,
      title: "Portfolio",
    },
    {
      id: 6,
      title: "Contact",
    },
  ];

  return (
    <header className="fixed top-0 z-50 flex flex-row justify-between w-full h-16 px-20 py-6 place-item-center bg-gray-50">
      <nav>
        <Link
          to={"About"}
          smooth={true}
          duration={450}
          className="cursor-pointer text-md font-primary "
        >
          . / Arumi
        </Link>
      </nav>
      <ul className="flex flex-row space-x-10">
        {menuItems.map((menu) => (
          <li>
            <Link
              to={menu.title}
              smooth={true}
              duration={450}
              className="duration-100 cursor-pointer text-md font-primary hover:text-black text-button-primary "
            >
              {menu.title}
            </Link>
          </li>
        ))}
      </ul>
    </header>
  );
};

export default Navbar;
