import React from "react";

export function CAETechnologies() {
  return (
    <div className="flex flex-col px-3 space-y-10 place-items-center font-primary">
      <div className="flex flex-col place-items-center ">
        <p className="text-lg font-black">Programming</p>
        <br></br>
        <div className="leading-loose text-center text-gray-500 text-md">
          <p>
            Python • HTML • TailwindCSS • Plotly Dash • SQL • Git • Terraform •
            Docker
          </p>
        </div>
      </div>

      <div className="flex flex-col place-items-center ">
        <p className="text-lg font-black">Libraries</p>
        <br></br>
        <div className="leading-loose text-center text-gray-500 text-md">
          <p>
            AzureSDK • NumPy • Pandas • Scikit-Learn • SciPy • Statsmodels •
            Plotly Dash • PyTorch • LLM (Langchain , Semantic Kernel) • FastAPI
            • PyTest
          </p>
        </div>
      </div>

      <div className="flex flex-col place-items-center ">
        <p className="text-lg font-black">Azure Services</p>
        <br></br>
        <div className="leading-loose text-center text-gray-500 text-md">
          <p>
            CosmosDB • Blob Storage • Active Directory • Data Factory •
            Databricks • Sharepoint • KeyVault • Web Apps • DevOps • Cognitive
            Search • OCR (Optical Character Recognition)
          </p>
        </div>
      </div>
    </div>
  );
}

export function SplitStepTechnologies() {
  return (
    <div className="flex flex-col px-3 space-y-10 place-items-center font-primary">
      <div className="flex flex-col place-items-center ">
        <p className="text-lg font-black">Programming</p>
        <br></br>
        <div className="leading-loose text-center text-gray-500 text-md">
          <p>
            ReactJS • Python • HTML • TailwindCSS • Docker • Git • GitLab CI
          </p>
        </div>
      </div>

      <div className="flex flex-col place-items-center ">
        <p className="text-lg font-black">Libraries</p>
        <br></br>
        <div className="leading-loose text-center text-gray-500 text-md">
          <p>
            Flask • Boto3 • Redis SDK • SQLAlchemy • Pandas • Ffmpeg •
            Ffmpeg.wasm • D3.js • ReactPlayer • MaterialUI • Selenium • Jest •
            Pytest
          </p>
        </div>
      </div>
    </div>
  );
}

export function CAEInternshipTechnologies() {
  return (
    <div className="flex flex-col px-3 space-y-10 place-items-center font-primary">
      <div className="flex flex-col place-items-center ">
        <p className="text-lg font-black">Programming</p>
        <br></br>
        <div className="text-center text-gray-500 text-md">
          <p>C# • Git</p>
        </div>
      </div>
    </div>
  );
}

export function MLEngTechnologies() {
  return (
    <div className="flex flex-col px-3 space-y-10 place-items-center font-primary">
      <div className="flex flex-col place-items-center ">
        <p className="text-lg font-black">Programming</p>
        <br></br>
        <div className="leading-loose text-center text-gray-500 text-md">
          <p>Python • Git • GitHub Actions • Docker</p>
        </div>
      </div>

      <div className="flex flex-col place-items-center ">
        <p className="text-lg font-black">Libraries</p>
        <br></br>
        <div className="leading-loose text-center text-gray-500 text-md">
          <p>
            Tensorflow • Keras • Apache Airflow • MLFlow • Boto3 • NumPy •
            Pandas • Scikit-Learn • Flask • Selenium • Unittest
          </p>
        </div>
      </div>

      <div className="flex flex-col place-items-center ">
        <p className="text-lg font-black">Azure Services</p>
        <br></br>
        <div className="leading-loose text-center text-gray-500 text-md">
          <p>S3 • DynamoDB • Redshift• EC2 • Secrets Manager</p>
        </div>
      </div>
    </div>
  );
}
