import React from "react";
import AnimatedText from "../components/AnimatedText";
import { TypeAnimation } from "react-type-animation";
import { UilLinkedinAlt } from "@iconscout/react-unicons";
import { UilGithubAlt } from "@iconscout/react-unicons";
import { UilMediumM } from "@iconscout/react-unicons";
import FadeInDiv from "../components/FadeIn";
import { UilFileDownloadAlt } from "@iconscout/react-unicons";
import { UilMessage } from "@iconscout/react-unicons";
import { Link } from "react-scroll";
// import portrait from "../assets/profile-modified-square.png";
import portrait from "../assets/profile-modified-min.png";

import "../css/FluidBorder.css";
export default class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="flex flex-row justify-center bg-gray-50 h-[100vh] min-h-[700px] pt-[50px] py-[10%] place-items-center z-10"
        id="About"
      >
        <main className="w-[60%] h-full flex flex-row py-32">
          <div className="w-[12%] h-[50%] flex flex-col justify-center space-y-14">
            <a
              className="cursor-pointer w-fit"
              title="click here"
              href="https://www.linkedin.com/in/aymen-rumi/"
              target="_blank"
            >
              <UilLinkedinAlt width="25px" height="25px" />
            </a>

            <a
              className="cursor-pointer w-fit"
              href="https://github.com/AymenRumi"
              target="_blank"
            >
              <UilGithubAlt width="25px" height="25px" />
            </a>

            <a
              className="cursor-pointer w-fit"
              href="https://medium.com/@aymen.rumi"
              target="_blank"
            >
              <UilMediumM width="25px" height="25px" />
            </a>
          </div>
          <div className="w-[57.5%] flex flex-col space-y-8">
            <h1 className="text-[42px] font-primary ">Aymen Rumi</h1>

            <h1 className="px-1 text-xl font-thin font-primary">
              <TypeAnimation
                sequence={[
                  "Data Scientist ",
                  1000,
                  "Data Scientist - Software Developer",
                  1000,
                  "Data Scientist ",
                  1000,
                  "",
                  1000,
                ]}
                wrapper="span"
                speed={50}
                repeat={Infinity}
                preRenderFirstString={false}
              />
            </h1>

            <p className="px-1 space-y-4 font-thin text-gray-500 text-md font-primary">
              <AnimatedText
                text="I am a data scientist specializing in data-driven software solutions development."
                delay={250}
              />

              <br />

              <AnimatedText
                text="I help transform your data into actionable insights."
                delay={1850}
              />

              <br />

              <AnimatedText
                text="4+ years experience building end-to-end analytics and AI products for companies."
                delay={3450}
              />

              <br />
            </p>

            <div className="flex flex-row w-full space-x-10">
              <FadeInDiv delay={4550}>
                <Link
                  className="hover:cursor-pointer flex flex-row p-4 px-4.5 space-x-2 text-base text-white bg-button-primary hover:bg-button-hover font-primary rounded-3xl"
                  to={"Contact"}
                  smooth={true}
                  duration={450}
                >
                  <p>Message</p>
                  <UilMessage />
                </Link>
              </FadeInDiv>

              <FadeInDiv delay={5150}>
                <a
                  className="flex flex-row p-4 px-4.5 space-x-2 text-base text-white bg-button-primary hover:bg-button-hover font-primary rounded-3xl"
                  href="/Resume.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>Download Resume</p>
                  <UilFileDownloadAlt />
                </a>
              </FadeInDiv>
            </div>
          </div>
          <div className="w-max-[350px] w-[30.5%] place-items-center flex flex-col">
            <FadeInDiv delay={5750}>
              {/* 5750 */}
              <img src={portrait} className="profile"></img>
            </FadeInDiv>
          </div>
        </main>
      </div>
    );
  }
}
