import React from "react";
import { Dialog } from "@material-ui/core";
import { Tab, Tabs, Box } from "@mui/material";
import PropTypes from "prop-types";
import { CAEAbout } from "./experience/About";
import { SplitStepAbout } from "./experience/About";
import { CAETechnologies } from "./experience/Technology";
import { SplitStepTechnologies } from "./experience/Technology";
import { CAEInternshipAbout } from "./experience/About";
import { CAEInternshipTechnologies } from "./experience/Technology";
import { MLEngAbout } from "./experience/About";
import { MLEngTechnologies } from "./experience/Technology";
import { ConcordiaCourses } from "./education/Courses";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className="h-[100%] p-3 px-10">{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default class ExperiencePopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      experienceAbout: {
        cae: <CAEAbout />,
        splitstep: <SplitStepAbout />,
        "7square": <MLEngAbout />,
        cae_internship: <CAEInternshipAbout />,
      },
      experienceSkills: {
        cae: <CAETechnologies />,
        splitstep: <SplitStepTechnologies />,
        "7square": <MLEngTechnologies />,
        cae_internship: <CAEInternshipTechnologies />,
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };

  componentDidUpdate(prevProps) {
    // Check if this.props.open changed from true to false
    if (prevProps.open === true && this.props.open === false) {
      // Introduce a 1-second delay before resetting activeTab to 0
      setTimeout(() => {
        this.setState({ activeTab: 0 });
      }, 500); // 1000 milliseconds = 1 second
    }
  }
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        PaperProps={{
          style: {
            borderRadius: "10px",
            height: "650px",
            width: "550px",
          },
        }}
      >
        <div className="">
          <Tabs
            value={this.state.activeTab}
            onChange={this.handleChange}
            aria-label="basic tabs example"
            variant="fullWidth"
            TabIndicatorProps={{ sx: { bgcolor: "rgb(63 63 70)" } }}
            sx={{
              "& button.Mui-selected": { color: "rgb(63 63 70)" },
            }}
          >
            <Tab label="About" disableRipple {...a11yProps(0)} />
            <Tab label="Technologies" disableRipple {...a11yProps(1)} />
          </Tabs>
        </div>

        <div className="w-full px-4 py-6 mt-4 h-[90%] overflow-y-scroll ">
          <div className="flex flex-col ">
            {this.state.activeTab === 0
              ? this.state.experienceAbout[this.props.experience]
              : this.state.experienceSkills[this.props.experience]}
          </div>
        </div>
      </Dialog>
    );
  }
}
