import logo from "../assets/cae.jpeg";
const ExperienceCard = (props) => {
  return (
    <div className=" flex flex-col w-[42.5%] h-[40%] bg-white rounded-xl shadow-sm border-[1px]">
      {/* hover:-translate-y-0.5 duration-300 */}
      <div className="flex flex-row justify-center w-full px-4 pt-5 pb-2 place-items-center">
        <a target="_blank" href={props.link}>
          <img
            src={props.logo}
            className="hover:cursor-pointer rounded-2xl place-items-center"
          ></img>
        </a>
      </div>
      <div className=" w-full flex flex-col h-[75%] px-3 justify-between py-3">
        <div className="flex flex-col space-y-1">
          <div className="flex flex-row justify-between px-1 place-items-center">
            <a target="_blank" href={props.link}>
              <h1 className="text-xl font-bold font-primary">
                {props.company}
              </h1>
            </a>

            <h1 className="text-sm font-thin text-gray-500 font-primary">
              {" "}
              {props.date}{" "}
            </h1>
          </div>
          <div className="px-1 pt-0.5 font-thin text-gray-500 h-fit text-md font-primary">
            {props.title}
          </div>
        </div>

        <div className="flex flex-row justify-end w-full px-1 pt-3 pb-1 text-sm font-thin text-gray-500 h-fit">
          {/* <button className="flex flex-row p-3 bg-button-primary rounded-xl place-items-center"> View Details → </button> */}
          <button
            className="flex flex-row space-x-2 duration-200 ease-in-out hover:translate-x-1 font-primary"
            onClick={props.toggle}
          >
            {" "}
            <p>View Details </p> <p className=""> →</p>{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExperienceCard;
