import React from "react";
import { Dialog } from "@material-ui/core";
import { Tab, Tabs, Box } from "@mui/material";
import PropTypes from "prop-types";
import { CAEAbout } from "./experience/About";
import { SplitStepAbout } from "./experience/About";
import { CAETechnologies } from "./experience/Technology";
import { SplitStepTechnologies } from "./experience/Technology";
import { CAEInternshipAbout } from "./experience/About";
import { CAEInternshipTechnologies } from "./experience/Technology";
import { MLEngAbout } from "./experience/About";
import { MLEngTechnologies } from "./experience/Technology";
import { SportsAnalyticsDetail } from "./portfolio/Details";
import { PortfolioDetail } from "./portfolio/Details";
import { RealTimeActivityDetail } from "./portfolio/Details";
import { RemaxPipeline } from "./portfolio/Details";

export default class PortfolioPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      portfolio: {
        sports_analytics: <SportsAnalyticsDetail />,
        portfolio: <PortfolioDetail />,
        real_time_activity: <RealTimeActivityDetail />,
        remax_pipeline: <RemaxPipeline />,
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        PaperProps={{
          style: {
            borderRadius: "10px",
            height: "650px",
            width: "500px",
          },
        }}
      >
        <div className="w-full px-4 py-6 mt-4 h-[90%] overflow-y-scroll ">
          <div className="flex flex-col ">
            {this.state.portfolio[this.props.experience]}
          </div>
        </div>
      </Dialog>
    );
  }
}
