import { UilChartLine } from "@iconscout/react-unicons";
import { UilSignalAlt3 } from "@iconscout/react-unicons";
import { UilApps } from "@iconscout/react-unicons";

const SkillsCard = (props) => {
  return (
    <div className="flex flex-col w-[32%] h-[fit] bg-white rounded-xl shadow-sm border-[1px] p-3">
      <div className="flex flex-row w-full">
        <div className="w-[50px] h-[50px] -translate-y-8 shadow-sm rounded-xl bg-button-primary place-items-center flex flex-row justify-center">
          {props.icon}
        </div>
      </div>

      <div className="px-1 text-lg font-thin -translate-y-4 font-primary">
        {props.title}
      </div>

      <p className="w-full px-2 font-thin leading-loose text-gray-500 h-fit text-md font-primary">
        {props.list}
      </p>
    </div>
  );
};

export default SkillsCard;
