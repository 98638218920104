import React from "react";
import PortfolioCard from "./PortfolioCard";
import analyticsImage from "../assets/analytics.png";
import portfolioImage from "../assets/portfolio.png";
import humanActivity from "../assets/human_activity.png";
import dataPipeline from "../assets/diagram.png";
import PortfolioPopUp from "../components/PortfolioPopUp";

export default class PortfolioMural extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: this.props.filter,

      listOfPortfolioCards: [
        <PortfolioCard
          image={analyticsImage}
          title={"Sports Analytics - Final Project"}
          description={
            "Python • Selenium • Plotly • Scikit-Learn • SciPy • Keras"
          }
          tags={["all", "academic"]}
          toggle={() => this.props.toggle("sports_analytics")}
          onclick={() =>
            window.open(
              "/SportsAnalytics_Final_Project.pdf",
              "_blank",
              "noopener,noreferrer",
            )
          }
        />,
        <PortfolioCard
          image={portfolioImage}
          title={"Portfolio Website"}
          description={
            "ReactJS  • TailwindCSS • Terraform • Docker • AWS ECS • AWS Route 53"
          }
          tags={["all", "personal"]}
          toggle={() => this.props.toggle("portfolio")}
          onclick={() =>
            window.open("https://www.arumi.io", "_blank", "noopener noreferrer")
          }
        />,
        <PortfolioCard
          image={dataPipeline}
          title={"Remax Data Pipeline"}
          description={
            "Python • Selenium • Celery • RabbitMQ • PyPI • PostgreSQL • Pydantic • Terraform • Docker • Bash"
          }
          tags={["all", "personal"]}
          toggle={() => this.props.toggle("remax_pipeline")}
          onclick={() =>
            window.open(
              "https://github.com/AymenRumi/remax-data-pipeline",
              "_blank",
              "noopener noreferrer",
            )
          }
        />,
        <PortfolioCard
          image={humanActivity}
          title={"Real Time Human Activity Recognition"}
          description={"Python • Kakfka • Pytorch • MLFLow"}
          tags={["all", "personal", "toy"]}
          toggle={() => this.props.toggle("real_time_activity")}
          onclick={() =>
            window.open(
              "https://github.com/AymenRumi/real-time-activity-recognition",
              "_blank",
              "noopener noreferrer",
            )
          }
        />,
        // <PortfolioCard
        //   image={stencilImage}
        //   title={"Iterative Stencil Computation"}
        //   description={
        //     "C++ • Parrallel Computing • MPI (Message Passing Interface)"
        //   }
        //   tags={["all", "personal", "toy"]}
        //   toggle={() => this.props.toggle("cae")}
        // />,
      ],
    };
  }

  filterPortfolioCards(listOfPortfolioCards, filterString) {
    return listOfPortfolioCards.filter((portfolioCard) => {
      const tags = portfolioCard.props.tags || []; // Default to an empty array if tags prop is not present
      return tags.includes(filterString);
    });
  }

  chunkArray(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

  render() {
    return (
      <div id="column" className="flex flex-col h-fit pb-10 pt-2 space-y-[3%]">
        {this.chunkArray(
          this.filterPortfolioCards(
            this.state.listOfPortfolioCards,
            this.props.filter,
          ),
          3,
        ).map((row, rowIndex) => (
          <div
            key={`row${rowIndex}`}
            className={`flex flex-row ${
              row.length === 3 ? "justify-between" : "space-x-[2%]"
            } w-full`}
          >
            {row.map((portfolioCard, cardIndex) => portfolioCard)}
          </div>
        ))}
      </div>
    );
  }
}
