import React from "react";
import FilterToggle from "../components/FilterToggle";
import PortfolioMural from "../components/PortfolioMural";
import PortfolioPopUp from "../components/PortfolioPopUp";

export default class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeButton: "all",
      openModal: false,
      project: "",
    };
    this.filterContent = this.filterContent.bind(this); // Bind the fun
  }

  openModal = (experience) => {
    this.setState({ openModal: true });
    this.setState({ project: experience });
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  filterContent(newFilter) {
    console.log(newFilter);
    this.setState({
      activeButton: newFilter,
    });

    console.log(this.state.activeButton);
  }

  render() {
    return (
      <div
        className="flex flex-row justify-center bg-gray-50 h-fit py-[75px] min-h-[700px]  place-items-center"
        id="Portfolio"
      >
        <main className="w-[68.5%] h-full flex flex-col space-y-6 ">
          <div className="flex flex-row justify-center w-full place-items-center">
            <h1 className="text-4xl font-primary">Portfolio</h1>
          </div>

          <div className="flex flex-row justify-center w-full place-items-center">
            <div className="justify-center space-y-4 font-thin text-center text-gray-500 text-md font-primary">
              <p>Sample of my public work.</p>
            </div>
          </div>
          <PortfolioPopUp
            open={this.state.openModal}
            handleClose={this.closeModal}
            experience={this.state.project}
          />
          <div className="flex flex-row justify-center w-full pt-2 space-x-2 font-primary">
            <FilterToggle
              current_id={this.state.activeButton}
              id="all"
              title="All"
              filter={this.filterContent}
            />
            <FilterToggle
              current_id={this.state.activeButton}
              id="academic"
              title="Academic"
              filter={this.filterContent}
            />
            <FilterToggle
              current_id={this.state.activeButton}
              id="personal"
              title="Personal"
              filter={this.filterContent}
            />
            <FilterToggle
              current_id={this.state.activeButton}
              id="toy"
              title="Toy Programs"
              filter={this.filterContent}
            />
          </div>

          <PortfolioMural
            filter={this.state.activeButton}
            toggle={this.openModal}
          />
        </main>
      </div>
    );
  }
}
