import { UilInfoCircle } from "@iconscout/react-unicons";
import { BootstrapTooltip } from "../BootstrapTooltip";

export function McGillCourses() {
  const math = [
    {
      name: "MATH 533 - Honours Regression & Analysis of Variance",
      link: "https://www.mcgill.ca/study/2019-2020/courses/math-533",
    },
    {
      name: "MATH 523 - Generalized Linear Models",
      link: "https://www.mcgill.ca/study/2022-2023/courses/math-523",
    },
    {
      name: "MATH 545 - Introduction to Time Series Analysis",
      link: "https://www.mcgill.ca/study/2022-2023/courses/math-545",
    },
    {
      name: "MATH 547 - Stochastic Processes ",
      link: "https://www.mcgill.ca/study/2023-2024/courses/math-547",
    },
  ];

  const cs = [
    {
      name: "COMP 302 - Programming Languages & Paradigms",
      link: "https://www.mcgill.ca/study/2023-2024/courses/comp-302",
    },
    ,
    {
      name: "COMP 303 - Software Design",
      link: "https://www.mcgill.ca/study/2023-2024/courses/comp-303",
    },
    {
      name: "COMP 310 - Operating Systems",
      link: "https://www.mcgill.ca/study/2023-2024/courses/comp-310",
    },
    {
      name: "COMP 322 - Intro to C++",
      link: "https://www.mcgill.ca/study/2023-2024/courses/comp-322",
    },
    {
      name: "COMP 330 - Theory of Computation",
      link: "https://www.mcgill.ca/study/2023-2024/courses/comp-330",
    },
    {
      name: "COMP 350 - Numerical Computing",
      link: "https://www.mcgill.ca/study/2023-2024/courses/comp-350",
    },
    {
      name: "COMP 417 - Introduction Robotics and Intelligent Systems",
      link: "https://www.mcgill.ca/study/2023-2024/courses/comp-417",
    },
    {
      name: "COMP 424 - Artificial Intelligence",
      link: "https://www.mcgill.ca/study/2023-2024/courses/comp-424",
    },
    {
      name: "COMP 462 - Computational Biology Methods ",
      link: "https://www.mcgill.ca/study/2023-2024/courses/comp-462",
    },
    {
      name: "COMP 551 - Applied Machine Learning",
      link: "https://www.mcgill.ca/study/2023-2024/courses/comp-551",
    },
    {
      name: "COMP 549 - Brain-Inspired Artificial Intelligence",
      link: "https://www.mcgill.ca/study/2023-2024/courses/comp-549",
    },
  ];

  return (
    <div className="flex flex-col px-3 space-y-10 place-items-center font-primary">
      <div className="flex flex-col place-items-center ">
        <div className="flex flex-row space-x-3 place-items-center">
          <p className="text-lg font-black">Computer Science</p>
          <BootstrapTooltip
            title="Upper Undergraduate & Graduate Courses"
            placement="right"
          >
            <div>
              <UilInfoCircle width="20px" height="20px" />
            </div>
          </BootstrapTooltip>
        </div>

        <br></br>
        <div className="leading-loose text-gray-500 text-md">
          <ul>
            {cs.map((cs, index) => (
              <li key={index}>
                <a
                  href={cs.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  {cs.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <br></br>
      <div className="flex flex-col place-items-center ">
        <div className="flex flex-row space-x-3 place-items-center">
          <p className="text-lg font-black">Mathematics & Statistics</p>
          <BootstrapTooltip title="Graduate Courses" placement="right">
            <div>
              <UilInfoCircle width="20px" height="20px" />
            </div>
          </BootstrapTooltip>
        </div>
        <br></br>
        <div className="leading-loose text-gray-500 text-md">
          <ul>
            {math.map((math, index) => (
              <li key={index}>
                <a
                  href={math.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  {math.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export function ConcordiaCourses() {
  const math = [
    {
      name: "MAST 679 - Topics in Math & Stats : Sports Analytics",
      link: "https://www.concordia.ca/content/dam/artsci/math-stats/docs/Outlines%202021-2022/MAST397_STAT497_MAST679_N_2_21.pdf",
    },
  ];

  const cs = [
    {
      name: "COMP 6341 - Computer Vision",
      link: "https://users.encs.concordia.ca/~wayang/425_6341_syllabus.pdf",
    },
    { name: "COMP 691 - Neuroimage Computing", link: "" },
  ];

  return (
    <div className="flex flex-col px-3 space-y-10 place-items-center font-primary">
      <div className="flex flex-col place-items-center ">
        <p className="text-lg font-black">Computer Science</p>
        <br></br>
        <div className="leading-loose text-gray-500 text-md">
          <ul>
            {cs.map((cs, index) => (
              <li key={index}>
                <a
                  href={cs.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  {cs.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <br></br>
      <div className="flex flex-col place-items-center ">
        <p className="text-lg font-black">Mathematics & Statistics</p>
        <br></br>
        <div className="leading-loose text-gray-500 text-md">
          <ul>
            {math.map((math, index) => (
              <li key={index}>
                <a
                  href={math.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  {math.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
