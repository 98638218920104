export async function requestSendMessage(name, email, message) {
  const data = {
    name: name,
    email: email,
    message: message,
  };

  try {
    const response = await fetch("http://0.0.0.0:8000/send_message", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    console.log(response);

    if (!response.ok) {
      throw new Error("Request failed with status " + response.status);
    }

    // Handle the "OK" response
    console.log("Request successful");
    return response;
  } catch (error) {
    // Handle any errors
    console.error("Error:", error.message);
  }
}
