import React from "react";
import ExperienceCard from "../components/ExperienceCard";
import caeLogo from "../assets/cae.jpeg";
import squareLogo from "../assets/realestate.png";
import tennisLogo from "../assets/tennis.jpeg";
import ExperiencePopUp from "../components/ExperiencePopUp";

export default class Experiences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      experienceType: "",
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal = (experience) => {
    this.setState({ openModal: true });
    this.setState({ experienceType: experience });
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  render() {
    return (
      <div
        className="flex flex-row justify-center bg-gray-50 h-fit py-[85px] min-h-[700px] place-items-center"
        id="Experiences"
      >
        <main className="w-[63.5%] h-full flex flex-col space-y-6">
          <div className="flex flex-row justify-center w-full h-fit place-items-center">
            <h1 className="text-4xl font-primary">Experiences</h1>
          </div>

          <div className="flex flex-row justify-center w-full h-fit place-items-center">
            <div className="justify-center space-y-4 font-thin text-center text-gray-500 text-md font-primary">
              <p>My professional roles.</p>
            </div>
          </div>
          <div className="flex flex-row justify-center w-full h-fit place-items-center">
            <div className="justify-center space-y-4 font-thin text-center text-gray-500 text-md font-primary"></div>

            <div className="flex flex-col justify-center w-full py-4 space-y-8 place-items-center">
              <div className="flex flex-row justify-center space-x-[5%] w-full  place-items-center">
                <ExperiencePopUp
                  open={this.state.openModal}
                  handleClose={this.closeModal}
                  experience={this.state.experienceType}
                />

                <ExperienceCard
                  logo={caeLogo}
                  company={"CAE Inc."}
                  title={"Data Scientist"}
                  date={"May 2021 - Present"}
                  link={"https://www.cae.com/"}
                  toggle={() => this.openModal("cae")}
                  experience={this.state.experienceType}
                />

                <ExperienceCard
                  logo={tennisLogo}
                  company={"SplitStep.ai"}
                  title={"Software Developer (Consultant • Part-Time)"}
                  date={"June 2022 - Sep 2023"}
                  link={"https://splitstep.ai/"}
                  toggle={() => this.openModal("splitstep")}
                  experience={this.state.experienceType}
                />
              </div>

              <div className="flex flex-row justify-center space-x-[5%] w-full place-items-center">
                <ExperienceCard
                  logo={squareLogo}
                  company={"7Square"}
                  title={"Machine Learning Engineer"}
                  date={"Aug 2020 - Apr 2021"}
                  link={"https://www.7square.ca/"}
                  toggle={() => this.openModal("7square")}
                  experience={this.state.experienceType}
                />
                <ExperienceCard
                  logo={caeLogo}
                  company={"CAE Inc."}
                  title={"Software Engineering Intern"}
                  date={"Summer 2018"}
                  link={"https://www.cae.com/"}
                  toggle={() => this.openModal("cae_internship")}
                  experience={this.state.experienceType}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
