import React from "react";

export default class FilterToggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: this.props.current_id === this.props.id,
    };
  }

  componentDidUpdate(prevProps) {
    // Check if the current_id prop has changed
    if (this.props.current_id !== prevProps.current_id) {
      this.setState({
        isActive: this.props.current_id === this.props.id,
      });
    }
  }

  render() {
    return (
      <button
        className={` ${
          this.state.isActive
            ? "text-white rounded-xl bg-button-primary"
            : " hover:bg-button-primary hover:text-white duration-50"
        } p-1 px-2 rounded-xl text-md`}
        onClick={() => this.props.filter(this.props.id)}
      >
        {this.props.title}
      </button>
    );
  }
}
