import React, { useState, useEffect } from "react";
import "../css/FadeInDiv.css"; // Import the CSS file for styling

const FadeInDiv = ({ children, delay }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [delay]);

  return (
    <div className={`fade-in-div ${isVisible ? "visible" : ""}`}>
      {children}
    </div>
  );
};

export default FadeInDiv;
