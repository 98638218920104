import React from "react";

export function CAEAbout() {
  return (
    <div className="flex flex-col px-3 space-y-10 place-items-center font-primary">
      <div className="flex flex-col place-items-center ">
        <p className="text-lg font-black">Contributions</p>
        <br></br>
        <div className="text-gray-500 text-md">
          <p>
            Lead developer (full-stack and data pipeline) and data analyst for
            simulator telemetry & grading training analytics dashboard for
            business aviation and civil aviation training clients.{" "}
            <a
              target="_blank"
              className="underline text-button-primary"
              href={
                "https://www.cae.com/news-events/press-releases/airasia-india-becomes-the-first-airline-in-india-to-use-the-revolutionary-ai-powered-cae-rise-training-system/"
              }
            >
              here
            </a>
          </p>
          <br></br>
          <p>
            Worked in close collaboration with UX/UI designers, subject matter
            experts (SMEs), developers, business analysts, and product/project
            managers for product delivery.
          </p>
          <br></br>
          <p>
            Researched the viability and business value of implementing
            statistical learning and deep learning methods. (Forecasting
            customer booking activity at CAE training centers • Predicting
            flight simulator exceedances with causal analysis through model interpretability method)
          </p>
          <br></br>
          <p>
            Developing for AI products leveraging LLM driven capabilities with
            OCR, Vector Databases & Azure Cognitive Services to improve internal
            delivery, reducing the reliance on third-party providers.
          </p>
          <br></br>
          {/* <p>
            Managed small team of junior data scientists for delivery of
            internal framework for tool comparison.
          </p> */}
        </div>
      </div>

      <div className="flex flex-col place-items-center">
        <p className="text-lg font-black">AI Strategy Team</p>
        <br></br>
        <p className="text-gray-500 text-md">
          <p className="text-black text-md">AI solutions</p> Development of
          productionized solutions and integration of new AI technologies into
          existing products.
          <br></br>
          <br></br>
          <p className="text-black text-md">AI consultation</p> Evaluation of
          potential AI opportunities and promotion of data best practices.
          <br></br>
          <br></br>
          <p className="text-black text-md">AI innovation</p> Assessment of
          emerging market needs and developing new AI technologies and
          proof-of-concepts.
        </p>
      </div>
    </div>
  );
}

export function SplitStepAbout() {
  return (
    <div className="flex flex-col px-2 space-y-10 place-items-center font-primary">
      <div className="flex flex-col place-items-center ">
        <p className="text-lg font-black">Contributions</p>
        <br></br>
        <div className="text-gray-500 text-md">
          {/* Currently confidential */}
          <p>
            Built SplitStep.ai’s web application in collaboration with team
            members in charge of computer vision based data generation system.{" "}
          </p>
          <br></br>
          <p>
            Responsible for end-to-end application development (front-end,
            back-end, continuous integration, unit and integration tests, and
            deployment (CICD) processes).
          </p>
          <br></br>
          <p>
            Responsible for data analytics capabilities for player performance
            and game evaluation.{" "}
          </p>

          <br></br>
          <p>Built custom data visualization components library.</p>
          <br></br>
          <p>
            Application includes video streaming and analysis tool with
            analytics for player and game metrics.
          </p>
        </div>
      </div>
    </div>
  );
}

export function CAEInternshipAbout() {
  return (
    <div className="flex flex-col px-2 space-y-10 place-items-center font-primary">
      <div className="flex flex-col place-items-center ">
        <p className="text-lg font-black">Contributions</p>
        <br></br>
        <div className="text-gray-500 text-md">
          <p>
            Developed testing functionalities (traceback logging, unit testing,
            integration testing) during code build for CAE Simfinity virtual
            simulator.{" "}
            <a
              target="_blank"
              href="https://www.cae.com/civil-aviation/aviation-simulation-equipment/training-equipment/ground-school-training-solutions/cae-simfinity-xr-series/"
              className="underline text-button-primary"
            >
              here
            </a>
          </p>
          <br></br>
          <p>
            Learned software programming best practices, under AGILE development
            team.
          </p>
          <br></br>
        </div>
      </div>
    </div>
  );
}

export function MLEngAbout() {
  return (
    <div className="flex flex-col px-2 space-y-10 place-items-center font-primary">
      <div className="flex flex-col place-items-center ">
        <p className="text-lg font-black">Contributions</p>
        <br></br>
        <div className="text-gray-500 text-md">
          <p>Developed 7Square's data and ML pipelines. </p>
          <br></br>
          <p>
            Responsible for the data engineering lifecycle (data collection,
            data modelling, data storage, data quality) for long term analytics
            and model training.
          </p>
          <br></br>
          <p>
            Responsible for increasing data extraction performance by building
            multithreaded web crawler solution for data collection.
          </p>
          <br></br>
          <p>
            Built automated workflow for scheduled model training and
            deployment, monitoring model evaluation metrics and drifts in
            statistical properties of dataset.
          </p>
          <br></br>

          <p>
            Trained models for house price evaluation in Toronto market based on
            geolocation data and sallelite image from Google Maps API.
          </p>
        </div>
      </div>
    </div>
  );
}
