import React from "react";
import SkillsCard from "../components/SkillsCard";

import { UilSignalAlt3 } from "@iconscout/react-unicons";
import { UilApps } from "@iconscout/react-unicons";
import { UilRobot } from "@iconscout/react-unicons";
import { UilServerAlt } from "@iconscout/react-unicons";
import { UilCodeBranch } from "@iconscout/react-unicons";
import { UilCloudDatabaseTree } from "@iconscout/react-unicons";
export default class Skills extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="flex flex-row justify-center bg-gray-50 h-fit py-[75px] min-h-[700px] place-items-center"
        id="Skills"
      >
        <main className="w-[67.5%] h-full flex flex-col space-y-8 ">
          <div className="flex flex-row justify-center w-full h-fit place-items-center">
            <h1 className="text-4xl font-primary">Skills</h1>
          </div>

          <div className="flex flex-row justify-center w-full h-fit place-items-center">
            <div className="justify-center space-y-4 font-thin text-center text-gray-500 text-md font-primary">
              <p>
                {" "}
                I build full-stack applications, tools, frameworks, data and ML
                pipelines, for data analytics, big-data, and AI applications.{" "}
              </p>

              <p>
                {" "}
                My work is rooted in statistical modelling, analysis, and
                development.{" "}
              </p>

              <p> Technologies I use. </p>
            </div>
          </div>

          <div className="flex flex-col w-full h-full py-4 space-y-12">
            <div className="flex flex-row justify-between w-full">
              <SkillsCard
                icon={<UilSignalAlt3 color="white" />}
                title={"Data Science"}
                list={
                  "Python • NumPy • Pandas • Scikit-Learn • SciPy • Statsmodels • Plotly • MLFlow "
                }
              />
              <SkillsCard
                icon={<UilApps color="white" />}
                title={"Frontend"}
                list={
                  "React.js • Next.js • Redux • Electron •  HTML • TailwindCSS • D3.js • Plotly Dash  "
                }
              />
              <SkillsCard
                icon={<UilRobot width="30px" height="30px" color="white" />}
                title={"AI"}
                list={
                  "PyTorch • Tensorflow • OpenCV • LLM (Langchain , Semantic Kernel)"
                }
              />
            </div>

            <div className="flex flex-row justify-between w-full">
              <SkillsCard
                icon={<UilServerAlt width="30px" height="30px" color="white" />}
                title={"Backend"}
                list={
                  "Python • Flask • FastAPI • Socket.IO • Celery • RabbitMQ• S3 • Azure Blob • Redis • SQLAlchemy"
                }
              />
              <SkillsCard
                icon={
                  <UilCodeBranch width="30px" height="30px" color="white" />
                }
                title={"DevOps"}
                list={
                  "Git • Docker • Terraform • GitHub Actions • Linux • PyTest • Selenium • CICD"
                }
              />
              <SkillsCard
                icon={
                  <UilCloudDatabaseTree
                    width="30px"
                    height="30px"
                    color="white"
                  />
                }
                title={"Data Engineering"}
                list={
                  "Airflow • Databricks • PySpark • Kafka • Selenium • SQL • NoSQL"
                }
              />
            </div>
          </div>
        </main>
      </div>
    );
  }
}
