import Navbar from "./components/Navbar";
import About from "./pages/About";
import Skills from "./pages/Skills";
import React from "react";
import Experiences from "./pages/Experiences";
import Education from "./pages/Education";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";

export default class App extends React.Component {
  screenHeight = () => {
    const screenHeight = window.innerHeight;
    console.log(screenHeight);
  };

  render() {
    return (
      <div className="w-full bg-gray-50">
        <Navbar />
        <main className="pb-[2%] bg-gray-50">
          <About />
          <Skills />
          <Experiences />
          <Education />
          <Portfolio />
          <Contact />
        </main>
      </div>
    );
  }
}
