export function SportsAnalyticsDetail() {
  return (
    <div className="flex flex-col px-3 space-y-10 place-items-center font-primary">
      <div className="flex flex-col place-items-center ">
        <p className="text-lg font-black">Description</p>
        <br></br>
        <div className="text-gray-500 text-md">
          <p>
            Final course project for graduate project course in sports
            analytics.
          </p>
          <br></br>
          <p>
            This study presents a machine learning framework using Convolutional
            Neural Networks (CNNs) to predict NBA team performance based on
            hypothetical player rosters.
          </p>
          <br></br>

          <p>
            It clusters historical team data, applies CNNs to estimate team
            metrics for new rosters, and identifies analogous historical teams.
          </p>

          <br></br>
          <p>
            <p>
              A Python module facilitates user engagement with the model for
              roster analysis.
            </p>
            {/* <u>Technical Explanation:</u>
          <br></br>
          <br></br>
          <ul>
  <li> • Data collection via web scraping for NBA team/player statistics from 1999 to 2020.</li>
  <li>Use of clustering (KMeans) and dimensionality reduction (PCA) to categorize teams.</li>
  <li>CNN model development trained on normalized player data with position encoding, leveraging kernel density estimation for sampling player minutes.</li>
  <li>Predictive analysis using CNNs with error metrics for performance evaluation and sampling with a kernel density estimator for hypothesizing player statistics.</li>
</ul> */}
          </p>
        </div>
      </div>
    </div>
  );
}

export function PortfolioDetail() {
  return (
    <div className="flex flex-col px-3 space-y-10 place-items-center font-primary">
      <div className="flex flex-col place-items-center ">
        <p className="text-lg font-black">Description</p>
        <br></br>
        <div className="text-gray-500 text-md">
          <p>Personal website with ReactJS, TailwindCSS and FastAPI.</p>
          <br></br>

          <p>
            CICD and AWS infrastructure handled via Terraform and Github
            Actions.
          </p>

          <br></br>

          <p>
            AWS Infrastructure - ECR • ECS • VPC • App Load Balancer • Route 53
          </p>

          <br></br>
          {/* <p>FastAPI server handles message requests.</p> */}
          <br></br>
        </div>
      </div>
    </div>
  );
}

export function RealTimeActivityDetail() {
  return (
    <div className="flex flex-col px-3 space-y-10 place-items-center font-primary">
      <div className="flex flex-col place-items-center ">
        <p className="text-lg font-black">Description</p>
        <br></br>
        <div className="text-gray-500 text-md">
          <p>Personal project for real-time detection use case.</p>

          <br></br>

          <p>
            Human sensor data is mocked as a stream using Kafka and handled for
            real time processing.
          </p>

          <br></br>

          <p>
            Deep learning model is trained with PyTorch with experiments
            conducted using MLFlow.
          </p>

          <br></br>
          <p>
            Convolutional Neural Network model is used to predict human activity
            from multidimentional time series data.
          </p>
          <br></br>
        </div>
      </div>
    </div>
  );
}

export function RemaxPipeline() {
  return (
    <div className="flex flex-col px-3 space-y-10 place-items-center font-primary">
      <div className="flex flex-col place-items-center ">
        <p className="text-lg font-black">Description</p>
        <br></br>
        <div className="text-gray-500 text-md">
          <p>
            Python library published as PyPI package used to implement an
            efficient web scraping ETL (Extract, Transform, Load).
          </p>

          <br></br>

          <p>
            The library uses Celery, an asynchronous task queue, in conjunction
            with RabbitMQ as a message broker. The workers, implemented with
            multithreaded selenium web drivers, allow for parallel celery
            workers running multithreaded scraping of listings from the REMAX
            website.
          </p>

          <br></br>
          <p>
            The extracted data undergoes transformation and validation based on
            a predefined data contract before being stored in an SQL database
            for long term analytics.
          </p>
          <br></br>
          <p>
            AWS infrastructure for data pipeline is maintained with Terraform
            (EC2, AutoScaling, Lambda, Cloudwatch, Secrets Manager, RDS, etc).
          </p>
        </div>
      </div>
    </div>
  );
}
