import React from "react";
import { withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import { UilMultiply } from "@iconscout/react-unicons";
import { requestSendMessage } from "../services/APIService";
import Loader from "./Loader";
// import Email from "../services/EmailService";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "rgb(63 63 70)",
      borderRadius: "20px",
    },
    "& .MuiInput-underline:after": {
      borderRadius: "20px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "20px",
      },
      "&:hover fieldset": {
        borderColor: "0",
        borderRadius: "20px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgb(63 63 70)",
        borderWidth: "1px",
        borderRadius: "20px",
      },
    },
  },
})(TextField);

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formFilled: false,
      loading: false,
      nameField: "",
      mailField: "",
      messageField: "",
      alert: false,
      success: false,
      error: false,
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleMailChange = this.handleMailChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleFormClick = this.handleFormClick.bind(this);
  }

  handleNameChange(event) {
    this.setState({ nameField: event.target.value });
    this.handleSubmit(
      event.target.value.trim().replace(/\s+/g, ""),
      this.state.mailField,
      this.state.messageField,
    );
  }

  handleMailChange(event) {
    this.setState({ mailField: event.target.value });
    this.handleSubmit(
      this.state.nameField,
      event.target.value.trim().replace(/\s+/g, ""),
      this.state.messageField,
    );
  }

  handleMessageChange(event) {
    this.setState({ messageField: event.target.value });
    this.handleSubmit(
      this.state.nameField,
      this.state.mailField,
      event.target.value.trim().replace(/\s+/g, ""),
    );
  }

  handleSubmit(name, mail, message) {
    if (name !== "" && mail !== "" && message !== "") {
      this.setState({ formFilled: true });
    } else {
      this.setState({ formFilled: false });
    }
  }

  setOpen(value) {
    this.setState({ alert: value });
  }

  setOpenError(value) {
    this.setState({ error: value });
  }

  handleFormClick = async () => {
    if (this.state.formFilled === false) {
      this.setState({ alert: true });
    } else {
      this.setState({ loading: true });

      try {
        const response = await requestSendMessage(
          this.state.nameField,
          this.state.mailField,
          this.state.messageField,
        );
        // const email = new Email();
        // const isSuccess = await email.send({
        //   name: 'John Doe',
        //   email: 'john@example.com',
        //   message: 'Hello there!'
        // });

        if (response.ok) {
          this.setState({ success: true, loading: false });
        } else {
          this.setState({ error: true, loading: false });
        }
        // if (isSuccess) {
        //   this.setState({ success: true, loading: false });
        // } else {
        //   this.setState({ success: false, loading: false });
        // }
      } catch (error) {
        console.error(error);
        this.setState({ error: true, loading: false });
      }
    }
  };

  render() {
    return (
      <div className="w-[50%] space-y-[7%] h-[80vh] flex flex-col place-items-center">
        <div className="flex flex-col space-y-8 w-fit place-items-center ">
          <p className="text-lg text-center font-primary">
            Write me your project / Send me a message
          </p>

          {!this.state.success ? (
            <div className="w-full ">
              <div className=" flex flex-col  h-full w-[100%]">
                <div className="w-full">
                  <Collapse in={this.state.alert}>
                    <Alert
                      severity="warning"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            this.setOpen(false);
                          }}
                        >
                          <UilMultiply width="20px" height="20px" />
                        </IconButton>
                      }
                      sx={{ mb: 2, fontFamily: "Poppins" }}
                    >
                      Please fill out missing fields
                    </Alert>
                  </Collapse>

                  <Collapse in={this.state.error}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            this.setOpenError(false);
                          }}
                        >
                          <UilMultiply width="20px" height="20px" />
                        </IconButton>
                      }
                      sx={{ mb: 2, fontFamily: "Poppins" }}
                    >
                      Temporary issue. Please send an email.
                    </Alert>
                  </Collapse>
                </div>

                <div className="flex flex-col space-y-8">
                  <CssTextField
                    className="w-full rounded-full bg-white"
                    id="outlined-basic"
                    label="Name"
                    rows={1}
                    variant="outlined"
                    autoComplete="off"
                    value={this.state.nameField}
                    onChange={this.handleNameChange}
                  />

                  <CssTextField
                    className="w-full bg-white "
                    id="outlined-basic"
                    label="Mail"
                    variant="outlined"
                    rows={1}
                    autoComplete="off"
                    value={this.state.mailField}
                    onChange={this.handleMailChange}
                  />

                  <CssTextField
                    className="w-full bg-white"
                    id="outlined-basic"
                    label="Project / Message"
                    multiline
                    rows={10}
                    variant="outlined"
                    autoComplete="off"
                    value={this.state.messageField}
                    onChange={this.handleMessageChange}
                  />
                </div>
              </div>

              {!this.state.loading ? (
                <div className="flex flex-row justify-center w-full">
                  {/* <Alert className="font-primary"  sx={{ fontFamily: "Poppins"}} > Message Received!</Alert> */}
                  <button
                    // disabled={!this.state.formFilled}
                    className={` ${
                      !this.state.formFilled
                        ? "hover:bg-button-hover"
                        : "hover:bg-button-hover"
                    }
             place-items-center flex flex-row p-3 px-3.5 space-x-2 text-base text-white bg-button-primary  font-primary rounded-3xl`}
                    onClick={this.handleFormClick}
                  >
                    <p>Submit</p>
                  </button>
                </div>
              ) : (
                <div className="flex flex-row justify-center w-full">
                  <Loader />
                </div>
              )}
            </div>
          ) : (
            <div className="w-full">
              <Alert severity="success" sx={{ mb: 2, fontFamily: "Poppins" }}>
                Message received! I will get back to you shortly.
              </Alert>
            </div>
          )}
        </div>
      </div>
    );
  }
}
