import React, { useEffect, useState } from "react";
import { UilEnvelope } from "@iconscout/react-unicons";

const ContactCard = (props) => {
  return (
    <div className="flex flex-col bg-white rounded-xl shadow-sm border-[1px] w-full h-[200px] place-items-center justify-around space-y-2">
      <div className="flex flex-col place-items-center">
        {props.icon}
        <p className="text-lg font-primary ">{props.name}</p>
        <p className="pt-1 text-sm text-gray-500 font-sm font-primary">
          {props.value}
        </p>
      </div>

      {props.button ? (
        props.email ? (
          <a
            className="flex flex-row space-x-2 text-sm font-thin text-gray-500 duration-200 ease-in-out hover:translate-x-1 font-primary"
            href="mailto:aymen.rumi@mail.mcgill.ca?"
          >
            <p>{props.buttonMessage} </p> <p className=""> →</p>{" "}
          </a>
        ) : (
          <a
            className="flex flex-row space-x-2 text-sm font-thin text-gray-500 duration-200 ease-in-out hover:translate-x-1 font-primary"
            href={props.link}
            target="_blank"
          >
            <p>{props.buttonMessage} </p> <p className=""> →</p>{" "}
          </a>
        )
      ) : (
        <button className="flex flex-row space-x-2 text-sm font-thin text-gray-500 duration-200 ease-in-out hover:translate-x-1 font-primary"></button>
      )}
    </div>
  );
};

export default ContactCard;
