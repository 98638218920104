import React from "react";
import ContactCard from "../components/ContactCard";
import { UilEnvelope } from "@iconscout/react-unicons";
import { UilLinkedin } from "@iconscout/react-unicons";
import Form from "../components/Form";

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="flex flex-row justify-center bg-gray-50 h-fit py-[75px] min-h-[700px] place-items-center"
        id="Contact"
      >
        <main className="w-[60%] h-full flex flex-col space-y-8 ">
          <div className="flex flex-row justify-center w-full h-fit place-items-center">
            <h1 className="text-4xl font-primary">Contact</h1>
          </div>

          <div className="flex flex-row justify-center w-full h-fit place-items-center">
            <div className="justify-center space-y-4 font-thin text-center text-gray-500 text-md font-primary">
              <p> Feel free to get in touch. </p>
            </div>
          </div>

          <div className="flex flex-row w-full h-full py-4 ">
            <div className="w-[50%] h-fit flex flex-col place-items-center space-y-3 ">
              <p className="text-lg font-primary">Talk to me</p>

              <div className="px-3 w-full h-[100%] space-y-8 ">
                <ContactCard
                  icon={
                    <UilEnvelope
                      width="35px"
                      height="35px"
                      color="rgb(63 63 70)"
                    />
                  }
                  name={"Email"}
                  value={"aymen.rumi@mail.mcgill.ca"}
                  button={true}
                  buttonMessage={"Email Me"}
                  email={true}
                />

                <ContactCard
                  icon={
                    <UilLinkedin
                      width="35px"
                      height="35px"
                      color="rgb(63 63 70)"
                    />
                  }
                  name={"LinkedIn"}
                  value={"linkedin.com/in/aymen-rumi"}
                  button={true}
                  buttonMessage={"Connect With Me"}
                  email={false}
                  link={"https://www.linkedin.com/in/aymen-rumi/"}
                />
              </div>
            </div>
            <Form />
          </div>
        </main>
      </div>
    );
  }
}
