import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const PortfolioCard = (props) => {
  return (
    <div className=" flex flex-col w-[32%]  h-[40vh] min-h-[300px] bg-white rounded-xl shadow-sm border-[1px] p-4">
      <img
        src={props.image}
        className="h-[55%] w-full rounded-2xl border-[1px]"
      ></img>

      <div className="flex flex-col h-[60%] justify-between pt-3  font-primary">
        <div className="space-y-1">
          <p className="text-lg font-bold font-primary"> {props.title}</p>
          {/* <div className="flex flex-row space-x-1">

            <p className="p-1 px-1.5 text-white text-xs font-thin bg-button-primary rounded-xl font-primary">Tensorflow</p>
          </div> */}
          <p className="text-xs font-thin text-gray-500 font-primary">
            {" "}
            {props.description}{" "}
          </p>
        </div>

        <div className="flex flex-row justify-between w-full">
          <button
            className="flex flex-row space-x-2 text-sm font-thin text-gray-500 duration-200 ease-in-out hover:translate-x-1 font-primary"
            onClick={props.toggle}
          >
            {" "}
            <p>View Details </p> <p className=""> →</p>{" "}
          </button>

          <button
            className="flex flex-row space-x-2 text-sm font-thin text-gray-500 duration-200 ease-in-out hover:translate-x-1 font-primary"
            onClick={props.onclick}
          >
            {" "}
            <p>View Project</p> <p className=""> →</p>{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PortfolioCard;
